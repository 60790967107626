import React, { useEffect, useState } from "react";
import BCGReactTour from '@bcg-web/bcg-react-tour';
import '@bcg-web/bcg-react-tour/dist/bcg-react-tour.css';
import CONFIG from "config";
import * as utils from '@kdshared/okta-utils';
import FloatingButton from "components/shared/FloatingButton/FloatingButton";
import authService from 'redux/auth';


export const GuidedTour = ({ show, tourId, showTourBtn, resetTourEvent, handleClick }) => {

    const preproductionPublisherId = "678f7f96b8e999f826aa60e2";
    const productionPublisherId = "678a3b795cac543a4f4614b4";
    const [showTourGuide, setShowTourGuide] = useState(false);
    const [accessToken, setAccessToken] = useState();

    useEffect(() => {
        setAccessToken(authService.getAccessToken());
    }, []);

    useEffect(() => {
        setShowTourGuide(show);
    }, [show]);
    return (
        <>
            {show && <BCGReactTour
                apiKey={CONFIG.PEOPLE_API_X_API_KEY}
                publisherId={productionPublisherId}
                appName="External Expert Hub"
                accessToken={accessToken}
                psid={utils.getPsId()}
                tourId={tourId}
                environment="production-internal"
                continuous="true"
                run={showTourGuide}
                callback={(event) => {
                    if (event.action == "reset") {
                        resetTourEvent();
                    }
                }}
            />}
            {showTourBtn && <FloatingButton id='tourBtn' onClick={handleClick} />}
        </>
    );
};