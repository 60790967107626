import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "containers/Layout";
import { Table, Checkbox } from 'semantic-ui-react';
import { getProjectAnalyticsAPIData, resetProjectAnalyticsAPIData } from "redux/actions/ProjectAnalytics/ProjectAnalytics_actions";
import { selectors as projectAnalyticsSelector } from "redux/reducers/ProjectAnalytics/ProjectAnalytics_reducer";
import { getUserId } from "utils/auth/auth";
import Image from "components/shared/Image";
import GreenSearchIcon from 'assets/images/greensearch_icon.svg';
import CrossIcon from "assets/images/filter-close.svg";
import ChargeCode from "assets/images/charge-code.svg";
import { PerformanceTable } from './PerformaceTable';
import { Bar } from 'react-chartjs-2';
import './ProjectAnalytics.scss';

import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export const ProjectAnalytics = () => {
    const dispatch = useDispatch();
    const projectAnalyticsReducer = useSelector(projectAnalyticsSelector.getProjectAnalyticsData) || null;
    const loadingData = useSelector(projectAnalyticsSelector.getLoading);
    const suggestionBoxRef = useRef(null);
    const analyticsData = [{
        chargeCode: '45-67890-98',
        details: 'Proposed to develop a SME strategy for a Bank and financial service firm in Asia',
        spendAmt: '120,000',
        requests: [
            '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)'
        ]
    },
    {
        chargeCode: '45-67890-98',
        details: 'Proposed to develop a SME strategy for a Bank and financial service firm in Asia',
        spendAmt: '120,000',
        requests: [
            '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)'
        ]
    },
    {
        chargeCode: '45-67890-98',
        details: 'Proposed to develop a SME strategy for a Bank and financial service firm in Asia Proposed to develop a SME strategy for a Bank and financial service firm in AsiaProposed to develop a SME strategy for a Bank and financial service firm in Asia',
        spendAmt: '120,000',
        requests: [
            '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)'
        ]
    },
    {
        chargeCode: '45-67890-98',
        details: 'Proposed to develop a SME strategy for a Bank and financial service firm in Asia',
        spendAmt: '120,000',
        requests: ['#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)'
        ]
    }
    ]
    const [getHrid, setHrid] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
    const [getSuggestionData, setSuggestionData] = useState([]);
    const [getNoRecord, setNoRecord] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedItems, setSelectedItems] = useState([]);
    const [getPeopleAPIError, setPeopleAPIError] = useState(false);

    useEffect(() => {
        setLoading(loadingData);
    }, [loadingData]);

    useEffect(() => {
        if (projectAnalyticsReducer?.length > 0) {
            setSuggestionData(projectAnalyticsReducer);
            setNoRecord(false);
        }
        else if (projectAnalyticsReducer?.length === 0 && projectAnalyticsReducer !== null) {
            setSuggestionData([]);
            setNoRecord(true);
        }
    }, [projectAnalyticsReducer]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionBoxRef?.current && !suggestionBoxRef?.current?.contains(event.target)) {
                setShowSuggestion(false);
                setSearchInput('');
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        fetchHrId();
    }, [history.location]);

    const fetchHrId = async () => {
        const hrid = await getUserId();
        if (hrid) {
            setPeopleAPIError(false);
        }
        else {
            setPeopleAPIError(true);
        }
        setHrid(hrid);
    }
    const handleSearchChange = (event) => {
        // setSuggestionData([]);
        event.stopPropagation();
        const searchTerm = event?.target?.value;
        setSearchInput(searchTerm);
        handleSearch(searchTerm);
    }

    const handleSearch = (search) => {
        setSuggestionData([]);
        if (search?.length > 0) {
            setShowSuggestion(true);
            dispatch(getProjectAnalyticsAPIData(getHrid, search?.trim()));
        }
        else if (search?.length === 0) {
            setShowSuggestion(false);
        }
    }
    const resetSearchedData = () => {
        setSearchInput('');
        dispatch(resetProjectAnalyticsAPIData());
    }

    const handleKeyUp = (e) => {
        if (e.keyCode === 32) {
            e.target.value = e.target.value + " "
            e.stopPropagation()
        }
    }

    const chartDataNetwork = {
        labels: ['Alphasights', 'Intollion India', 'Coleman', 'Dialectica'],
        datasets: [
            {
                data: [65, 59, 80, 81, 56, 55, 40],
                backgroundColor: '#A97CDD',
                borderColor: '#A97CDD',
                borderWidth: 0,
            },
        ],
    };

    const chartDataExpert = {
        labels: ['Customer', 'Former', 'Supplier', 'Competitor'],
        datasets: [
            {
                data: [15, 29, 40, 31, 56, 25, 40],
                backgroundColor: '#0977D7',
                borderColor: '#0977D7',
                borderWidth: 0,
            },
        ],
    };

    const chartOptions = {
        scales: {
            x: {
                grid: {
                    display: false, // Hide grid lines on x-axis
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false, // Hide grid lines on x-axis
                },
            },
        },
        plugins: {
            legend: {
                display: false, // Hide legend
            },
        },
        indexAxis: 'y',
    };
    const handleCheckBoxChange = (event, item) => {
        const checked = item.checked;

        setSelectedItems((prevSelected) => {
            const updatedSelected = checked
                ? [...prevSelected, item?.label?.chargeCode]
                : prevSelected.filter((selectedItem) => selectedItem !== item?.label?.chargeCode);

            console.log('Updated selectedItems>>>', updatedSelected); // Log the updated state
            return updatedSelected;
        });
    };

    const handleDivCheckBoxChange = (event, item) => {
        const checked = event?.target?.checked;

        setSelectedItems((prevSelected) => {
            const updatedSelected = checked
                ? [...prevSelected, item?.chargeCode]
                : prevSelected.filter((selectedItem) => selectedItem !== item?.chargeCode);

            console.log('Updated selectedItems>>>', updatedSelected); // Log the updated state
            return updatedSelected;
        });
    };

    const handleDivClick = (event, item) => {
        // Trigger the checkbox change manually on div click
        const checkboxEvent = { target: { checked: !selectedItems.includes(item?.chargeCode) } };
        handleDivCheckBoxChange(checkboxEvent, item);  // Call handleCheckBoxChange
    };

    
    return (
        <Layout id="home" layoutClass="layout__my-navigator">
            <div className="ProjectAnalytics__wrap">
                <div className="analytics__searchWrap">
                    <div className="search--text">Use the search box to add multiple Interview Request IDs or Charge Codes and view consolidated analytics.</div>
                    <div className="search--field">
                        <div className="search__box">
                            <input type="number"
                                value={searchInput}
                                onChange={(e) =>
                                    handleSearchChange(e)
                                }
                                onKeyUp={(e) => {
                                    handleKeyUp(e);
                                }
                                }
                                placeholder="Enter Interview Request IDs or Charge Codes"
                                className="search--input" />
                            {
                                searchInput?.length > 0 &&
                                <Image src={CrossIcon} alt="CrossIcon" className="cross-icon" onClick={() => {
                                    resetSearchedData();
                                }} />
                            }
                            {
                                searchInput?.length == 0 &&
                                <Image src={GreenSearchIcon} alt="search icon" className={`search--icon`}
                                // onClick={() => handleSearch(searchInput)}
                                />
                            }
                        </div>
                        {
                            !getPeopleAPIError ?
                                <>
                                    {

                                        showSuggestion &&
                                        <div className="suggestion__wrap" ref={suggestionBoxRef}>
                                            {
                                                !getNoRecord ?
                                                    <>
                                                        {
                                                            getSuggestionData?.map((item, index) => (
                                                                <div className="suggestion__itemWrap" key={item?.id} onClick={(e) => handleDivClick(e, item)}>
                                                                    <div className="suggestion__left">
                                                                        <Checkbox
                                                                            label={item}
                                                                            checked={selectedItems?.includes(item?.chargeCode)}
                                                                            // onChange={handleCheckBoxChange}
                                                                            // onChange={(e) => handleCheckBoxChange(e, item)}
                                                                            // onChange={(event) => handleCheckBoxChange(event, item)}
                                                                            className='een-checkbox table--check' />
                                                                    </div>
                                                                    <div className="suggestion__right">
                                                                        <div className="right__top">
                                                                            <div className="req--id">
                                                                                Charge code :
                                                                            </div>
                                                                            <div className="req--idVal">
                                                                                {item?.chargeCode}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }</>
                                                    :
                                                    <>
                                                        <div className="suggestion__itemWrap">
                                                            Currently, no charge codes exist.
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    <div className="people--error left--align">
                                        Something went wrong. Please try again.
                                    </div>
                                </>
                        }
                    </div>
                </div>

                <div className="analytics__body">
                    <div className="body--text">Showing Analytics for </div>

                    <div className={hoveredItemIndex !== null ? "no--overflow body__wrap" : "body__wrap"}>
                        {
                            analyticsData?.map((item, index) => (
                                <><div className={`body__item ${hoveredItemIndex === index ? 'hovered' : ''}`} key={index} onMouseEnter={() => setHoveredItemIndex(index)} // Set hovered index
                                    onMouseLeave={() => setHoveredItemIndex(null)} >
                                    <>
                                        <Image src={CrossIcon} alt="CrossIcon" className="chargecross-icon" />
                                        <div className="charge__code">
                                            <Image src={ChargeCode} alt="ChargeCode" className="item-img" />
                                            <div className="code--val">Charge Code : {item?.chargeCode}</div>
                                        </div>

                                        <div className="code--text">{item?.details}</div>

                                        <div className="spend--text">Actual Spend</div>
                                        <div className="code--spend">
                                            ${item?.spendAmt}
                                        </div>
                                    </>
                                    <div className={`requests__wrap `}>
                                        <div className='request--text'>Requests</div>
                                        {item?.requests?.map((subItem, subIndex) => (
                                            <div className="requests__item" key={subIndex}>
                                                <div className="request--detail">
                                                    a. {subItem}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div></>
                            ))
                        }
                    </div>
                </div>

                <div className="lower__body">
                    <div className="spend__overView overview-block">
                        <div className="block-header">Spend Overview</div>
                        <div className="block-sub-heading">Total Estimated Spend <span className="highlighted-text">$ 600,000</span></div>
                        <div className="chart-overview">
                            <div className="spend-chart1 chart-block">
                                <div className="chart-heading1">Actual spend</div>
                                <div className="chart-heading2">By Network</div>
                                <Bar data={chartDataNetwork} options={chartOptions} />
                            </div>
                            <div className="spend-chart2 chart-block">
                                <div className="chart-heading1">Actual spend</div>
                                <div className="chart-heading2">by Expert Type</div>
                                <Bar data={chartDataExpert} options={chartOptions} />
                            </div>
                        </div>
                    </div>
                    <div className="performance__overview overview-block">
                        <div className="block-header">Performance Overview</div>
                        <div className="block-sub-heading ">Vendor Performance</div>
                        <div className="vendortable--text">Percentage of total project engagements facilitated by each expert network.</div>
                        <PerformanceTable />
                    </div>
                </div>
            </div>
        </Layout>
    )
}