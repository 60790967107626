import React, { useState, useEffect, useRef, useContext } from 'react';
import DownArrow from "assets/images/down_arrow.svg";
import { Image, Button } from 'semantic-ui-react';
import AddMoreIcon from "assets/images/add_more.svg";
import RemoveIcon from "assets/images/remove_icon.svg";
import './ExpertContact.scss';
import { FormContext } from 'context/form-context';
import { PeopleSelector } from '@kdshared/peopleselector';

export const ExpertContact = ({ selectedProviders, prevSelection, isDisabled, handleSelManagers, accessToken, apiKey, appName, placeholderTxt }) => {
    const popupRef = useRef(null);

    const formContext = useContext(FormContext);

    const [selectedProvidersData, setSelectedProvidersData] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [resManagerObjAdded, setResManagerObjAdded] = useState(false);
    const [resManagerObj, setResManagerObj] = useState([{
        expert: '',
        member: '',
        email: ''
    }]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [popupDropDownOptions, setPopupDropDownOptions] = useState([]);

    useEffect(() => {
        if (selectedProviders) {
            setSelectedProvidersData(selectedProviders);
            setPopupDropDownOptions(selectedProviders);

            let expertObjData = resManagerObj;

            let resManagerObjTemp = expertObjData.filter(obj => selectedProviders.includes(obj.expert));
            if (resManagerObjTemp?.length == 0) {
                setResManagerObj([{ expert: '', member: '', email: '' }]);
            } else {
                if (resManagerObjTemp?.length > resManagerObj?.length) {
                    setResManagerObj([...resManagerObjTemp, { expert: '', member: '', email: '' }]);
                } else {
                    setResManagerObj(resManagerObjTemp);
                }

            }

            let resManagers = resManagerObjTemp.map(obj => obj.expert);
            let filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));
            if (resManagerObjTemp?.length > 0) {
                if (filteredSelectedProviders?.length > 0) {
                    setActiveIndex(resManagerObjTemp?.length - 1);
                    setResManagerObjAdded(!resManagerObjAdded);
                }
                else {
                    setActiveIndex(null);
                }
            }
            else if (resManagerObj?.length == 2) {
                setActiveIndex(0);
            }
            resManagers = resManagerObj.map(obj => obj.expert);
            // Filtering selectedProviders to contain only those values not present in resManagers
            filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));

            setPopupDropDownOptions(filteredSelectedProviders);
        }
    }, [selectedProviders]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false);
            }
        };

        const handleMouseDown = (event) => {
            // Check if the down arrow image was clicked
            if (!event.target.classList.contains('arrow--img') && !event.target.classList.contains('dropdown--textbox')) {
                handleClickOutside(event);
            }
        };
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);

    useEffect(() => {
        if (resManagerObj.length > 0) {
            let tempExpertData = [];
            resManagerObj.forEach((obj) => {
                if (obj.member || obj.expert) {
                    tempExpertData.push(obj);
                }
            });
            if (tempExpertData.length > 0) {
                formContext.toggleFormValues('expertContact', tempExpertData, false)
            }
            // tempExpertData.length > 0 ? formContext.toggleFormValues('expertContact', tempExpertData, false) : '';
        }
    }, [resManagerObj]);

    const handleSelectProvider = (event, index, field) => {
        const { outerText } = event.target;
        const updatedResManagerObj = [...resManagerObj];
        updatedResManagerObj[index][field] = outerText;
        setResManagerObj((values) => {
            const updatedItems = [...values];
            if (updatedItems[index]) {
                updatedItems[index][field] = outerText;
                const findSavedExperts = prevSelection.find((selection) => selection.name == outerText);
                let savedContact = [];
                if (findSavedExperts) {
                    findSavedExperts.member.forEach((mem) => {
                        savedContact.push(mem.id);
                    });
                }
                updatedItems[index]['savedExpert'] = savedContact?.length > 0 ? [savedContact] : [];
            }
            return updatedItems;
        });
        setIsPopupOpen(false);
        // Filter out the dropdown options that are already selected in other input fields
        const dropdownOptions = selectedProvidersData.filter(option =>
            !updatedResManagerObj.some(obj => obj.expert === option)
        );
        setPopupDropDownOptions(dropdownOptions);
    }

    const handleAddMore = (index) => {
        if (resManagerObj[index].expert !== '') {
            if (index === activeIndex && popupDropDownOptions?.length !== 0) {
                // If the "Add More" button is clicked, add a new row
                setResManagerObj((values) => {
                    const updatedItems = [...values];
                    updatedItems.push({ expert: '', member: '', email: '' });
                    return updatedItems;
                });
                setResManagerObjAdded(!resManagerObjAdded);
                setActiveIndex(index + 1);
            } else {
                // If the "Remove" button is clicked, remove the corresponding row
                const updatedResManagerObj = resManagerObj.filter((_, idx) => idx !== index);

                if (updatedResManagerObj.length === 1) {
                    setResManagerObj(updatedResManagerObj);
                    setActiveIndex(0);

                    let resManagers = updatedResManagerObj.map(obj => obj.expert);

                    // Filtering selectedProviders to contain only those values not present in resManagers
                    let filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));
                    setPopupDropDownOptions(filteredSelectedProviders);

                } else if (updatedResManagerObj.length === 0) {
                    setResManagerObj([{ expert: '', member: '', email: '' }]);
                    setActiveIndex(0);
                    let resManagers = updatedResManagerObj.map(obj => obj.expert);

                    // Filtering selectedProviders to contain only those values not present in resManagers
                    let filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));
                    setPopupDropDownOptions(filteredSelectedProviders);
                }
                else {
                    setResManagerObj(updatedResManagerObj);
                    setActiveIndex(updatedResManagerObj?.length - 1);

                    let resManagers = updatedResManagerObj.map(obj => obj.expert);

                    // Filtering selectedProviders to contain only those values not present in resManagers
                    let filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));
                    setPopupDropDownOptions(filteredSelectedProviders);
                }
            }
        }
    };

    const updateSelectionValue = (e, fieldIndex) => {
        if (fieldIndex > -1) {
            setResManagerObj((values) => {
                const updatedItems = [...resManagerObj];
                if (updatedItems[fieldIndex]) {
                    updatedItems[fieldIndex]['member'] = e.selectionArray?.length > 0 ? e.selectionArray : [];
                    updatedItems[fieldIndex]['savedExpert'][0] = [];
                    if (e.selectionArray?.length > 0) {
                        e.selectionArray.forEach((ele) => {
                            updatedItems[fieldIndex]['savedExpert'][0].push(ele.id);
                        });
                    }
                    updatedItems[fieldIndex]['duplicateMembers'] = [];
                }
                return updatedItems;
            });
        }
    }

    return (
        <div className='expertContact__wrap'>
            <div className='expertContact__subwrap'>
                {resManagerObj.map((item, index) => (
                    <div className='expertContact__body' key={index}>
                        <div className="expertContact__input-email width70">
                            <div className="input__wrapper">
                                <input type='text'
                                    className={isDisabled ? "field-border disabled" : "dropdown--textbox field-border"}
                                    disabled={isDisabled}
                                    htmlFor="providersData" readOnly placeholder={placeholderTxt}
                                    value={item?.expert}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        if (activeIndex <= index) {
                                            setActiveIndex(index);
                                            setIsPopupOpen((prevIsPopupOpen) => !prevIsPopupOpen);
                                        }
                                    }}></input>
                                <Image className={isDisabled ? "arrow--img imgOpacity" : "arrow--img"} src={DownArrow} alt="DownArrow"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        if (activeIndex <= index) {
                                            setActiveIndex(index);
                                            setIsPopupOpen((prevIsPopupOpen) => !prevIsPopupOpen);
                                        }
                                    }} />
                                {
                                    isPopupOpen && activeIndex === index &&
                                    <div className="provider__popup" ref={popupRef}>
                                        <ul>
                                            {
                                                popupDropDownOptions?.map((data, popupIndex) => (
                                                    <li key={popupIndex} onClick={(event) => handleSelectProvider(event, index, 'expert')}>{data}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                }
                                {index === activeIndex && <div className='help-txt'>
                                    <span>Use this field to designate a team member as the main point of contact for an Expert Type/Module.</span>
                                </div>}
                            </div>
                            <div className='email__wrapper'>
                                {isDisabled ?
                                    <input type='text' className={isDisabled ? "field-border disabled" : "field-border"}
                                        disabled={isDisabled} /> :
                                    <PeopleSelector
                                        label=''
                                        name={'contact' + index}
                                        isInputFirst={true}
                                        apiKey={apiKey}
                                        accessToken={accessToken}
                                        environment='production-internal'
                                        fieldHeight="48px"
                                        preFillData={item?.savedExpert}
                                        isStateReset={true}
                                        onChange={(e) => updateSelectionValue(e, index)}
                                    />
                                }
                            </div>
                        </div>

                        <Button className={isDisabled ? "addmore__btn imgOpacity" : "addmore__btn"} onClick={() => !isDisabled && handleAddMore(index)}>
                            <Image className="arrow--img" src={index === activeIndex && selectedProviders.length - 1 != activeIndex ? AddMoreIcon : RemoveIcon} alt="AddMoreIcon" />
                            {index === activeIndex && selectedProviders.length - 1 != activeIndex ? "Add More" : "Remove"}
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    )
}