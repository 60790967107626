import { PROJECT_ANALYTICS } from 'redux/constants';

//  People CCO actions
export const getProjectAnalyticsAPIData = (hrid, chargeCode) => ({
    type: PROJECT_ANALYTICS.API_PROJECT_ANALYTICS_DATA,
    payload: {
        hrid: hrid,
        chargeCode: chargeCode
    }
});

export const getProjectAnalyticsAPIDataPending = () => ({
    type: PROJECT_ANALYTICS.API_PROJECT_ANALYTICS_RESULTS_PENDING
});

export const getProjectAnalyticsAPIDataSuccess = (payload) => ({
    type: PROJECT_ANALYTICS.API_PROJECT_ANALYTICS_RESULTS_SUCCESS,
    payload
});

export const getProjectAnalyticsAPIDataFailure = (error) => ({
    type: PROJECT_ANALYTICS.API_PROJECT_ANALYTICS_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});


export const resetProjectAnalyticsAPIData = () => ({
    type: PROJECT_ANALYTICS.API_PROJECT_ANALYTICS_RESULTS_RESET
})