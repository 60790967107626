import { put, call, takeEvery } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { OPEN_REQUESTS } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
    getOpenRequestAPIDataPending,
    getOpenRequestAPIDataFailure,
    getOpenRequestAPIDataSuccess
} from "./openRequests.actions";

import { setError } from '../error/error.actions';


export function* getOpenRequestAPIData(data) {
    let requestType = data?.payload?.status ? data?.payload?.status?.toLowerCase() : 'open';
    let requestURL = `${CONFIG.API_URL.POST_INTERVIEW_REQUEST}?hrId=${data?.payload?.hrId}&requestStatus=${data?.payload?.status}`;
    if (requestType == 'closed') {
        requestURL = data?.payload?.searchString ? `${CONFIG.API_URL.POST_INTERVIEW_REQUEST}/${requestType}?hrId=${data?.payload?.hrId}&page=${data?.payload?.page}&size=10&chargeCode=${data?.payload?.searchString}` :
            `${CONFIG.API_URL.POST_INTERVIEW_REQUEST}/${requestType}?hrId=${data?.payload?.hrId}&sortOrder=${data?.payload?.sort}&page=${data?.payload?.page}&size=10`;
    }

    try {
        const axiosConfig = {
            method: 'GET',
            endpoint: requestURL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
        };
        yield put(getOpenRequestAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            yield put(getOpenRequestAPIDataSuccess(response));
        }
        else {
            yield put(getOpenRequestAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        }
    }
    catch (err) {
        // yield (put(setError(err)));
        yield put(getOpenRequestAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* openRequestAPISaga() {
    try {
        yield takeEvery(OPEN_REQUESTS.API_OPEN_REQUESTS_DATA, getOpenRequestAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}